<template>
    <div></div>
</template>
<script>
    export default {
        name:'Store',
        props:['url'],
        data(){
            return {}
        },
        mounted(){
            this.$store.state.url     = this.url+'/';
        }
    }
</script>
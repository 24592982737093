/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// require('./bootstrap');
window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

var token = document.querySelector("meta[name='_token']");
if(token) window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.value

window.Vue = require('vue').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('instance-contact', require('./components/InstanceContact.vue').default);
Vue.component('new-parcel', require('./components/parcel/NewParcel.vue').default);
Vue.component('store', require('./components/Store.vue').default);
Vue.component('parcel-track', require('./components/parcel/ParcelTrack.vue').default);
Vue.component('forgot-password', require('./components/ForgotPassword.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import {store} from './store';
const app = new Vue({
    el: '#app',
    data(){
        return {
            payment_method:''
        }
    },
    store,
});

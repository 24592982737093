<template> 
    <div class="user_option w100 pt-2">
        <!-- SESSION CART -->
        <div class="shipping-const" :class="isOpenCart ? 'open-cart': ''" @click="openCart">
            <span class="icon">
                <i class="fas fa-shipping-fast"></i>
                <span class="cost-amount">৳{{shippingCost}}</span>
            </span>
            <div class="cost-info">

                <div class="location-wrapper" :title="shipping_info.parcel_from + ' To '+shipping_info.parcel_to">
                    <p class="location">
                        <span v-if="shipping_info.parcel_from!='...'">{{shipping_info.parcel_from}}</span> 
                    </p>
                    <p class="location">
                        <span v-if="shipping_info.parcel_from!='...'"> To </span>
                    </p>
                    <p class="location">
                        <span v-if="shipping_info.parcel_from!='...'">{{shipping_info.parcel_to}}</span>
                    </p>
                </div>

                <strong class="shipping-cart-title">Shipping Charge</strong>

                <table class="shipping-charge-table">
                    <tr>
                        <th>Parcel</th>
                        <td>: {{shipping_info.parcel_type}}</td>
                    </tr>
                    <tr>
                        <th>price</th>
                        <td>: {{shipping_info.product_price}}৳</td>
                    </tr>
                    <tr>
                        <th>Charge</th>
                        <td>: {{shipping_info.delivery_charge}}৳</td>
                    </tr>
                    <tr>
                        <th><span title="Cash On Delivery">COD</span>({{shipping_info.cod_percentage}}%)</th>
                        <td>: {{shipping_info.cod_amount}}৳</td>
                    </tr>
                    <tr>
                        <th>
                            <span v-if="(product_price > 0)">Total Price</span>    
                            <span v-else >Charge</span> 
                        </th>
                        <td>: {{shipping_info.total_price}}৳</td>
                    </tr>
                </table>
                <div class="notice">{{shipping_info.notice}}</div>
            </div>
        </div>
        <!-- END SESSION CART -->


        <form @submit.prevent="submit" class="w100">
            <fieldset class="w100 pb-3">
                <legend>Shipping Info</legend>

                <div class="form-group row w100">
                    <label class="col-md-4 text-md-right mt-2">Customer Name <span class="required">*</span></label>
                    <div class="col-md-6">
                        <input type="text" v-model="customer_name" class="form-control" placeholder="Customer Name" required>
                    </div>
                </div>

                <div class="form-group row w100">
                    <label class="col-md-4 text-md-right mt-2">Customer Mobile <span class="required">*</span></label>
                    <div class="col-md-6">
                        <input type="text" v-model="customer_mobile" class="form-control" placeholder="Customer Mobile" required>
                    </div>
                </div>

                <div class="form-group row w100">
                    <label class="col-md-4 text-md-right mt-2">Shipping Address <span class="required">*</span></label>
                    <div class="col-md-6">
                        <textarea v-model="shipping_address" class="form-control" placeholder="Full Shipping Address" required></textarea>
                    </div>
                </div>

                <div class="form-group row w100">
                    <div class="col-md-4 mb-md-2">
                        <select v-model="shipping_division_id" class="form-control selectpicker" data-live-search="true" required>
                            <option value="">Division</option>
                            <option v-for="division in divisions" :value="division.id">{{division.name}}</option>
                        </select>
                    </div>

                    <div class="col-md-4 mb-md-2">
                        <select v-model="shipping_district_id" class="form-control selectpicker" data-live-search="true" required>
                            <option value="">District</option>
                            <option v-for="row in shipping_districts" :value="row.id">{{row.name}}</option>
                        </select>
                    </div>
                    
                    <div class="col-md-4 mb-md-2">
                        <select v-model="shipping_thana_id" class="form-control selectpicker" data-live-search="true" required>
                            <option value="">Area</option>
                            <option v-for="row in shipping_thanas" :value="row.id">{{row.name}}</option>
                        </select>
                    </div>
                </div>
            </fieldset>

            <fieldset class="w100 pb-3">
                <legend>Parcel Info</legend>

                <div class="form-group row w100">
                    <label class="col-md-4 text-md-right mt-2">Parcel Quantity <span class="required">*</span></label>
                    <div class="col-md-6">
                        <input type="number" v-model="parcel_quantity" class="form-control" placeholder="Enter Your Parcel Quantity" min="1" required>
                    </div>
                </div>

                <div class="form-group row w100">
                    <label class="col-md-4 text-md-right mt-2">Product Title <span class="required">*</span></label>
                    <div class="col-md-6">
                        <input type="text" v-model="product_title" class="form-control" placeholder="Enter Your Product Titile" required>
                    </div>
                </div>

                <div class="form-group row w100">
                    <label class="col-md-4 text-md-right mt-2">Product Category <span class="required">*</span></label>
                    <div class="col-md-6">
                        <select v-model="parcel_category_id" class="form-control selectpicker" data-live-search="true" required>
                            <option v-for="row in categories" :value="row.id">{{row.category}}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group row w100">
                    <label class="col-md-4 text-md-right mt-2">Parcel Weight <span class="required">*</span></label>
                    <div class="col-md-6">
                        <select v-model="package_weight_id" class="form-control selectpicker" data-live-search="true" required>
                            <option v-for="row in weights" :value="row.id">{{row.name}}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group row w100">
                    <label class="col-md-4 text-md-right mt-2">Product Price <span class="required">*</span></label>
                    <div class="col-md-6">
                        <input type="number" v-model="product_price" class="form-control" placeholder="Enter Your Product Price" required>
                    </div>
                </div>

                <div class="form-group row w100">
                    <label class="col-md-4 text-md-right mt-2">
                        <span v-if="(product_price > 0)">Total Price</span>    
                        <span v-else >Charge</span>    
                    <span class="required">*</span></label>
                    <div class="col-md-6">
                        <input type="text" :value="shipping_info.total_price" class="form-control" placeholder="Total Price" readonly>
                    </div>
                </div>

                <div class="form-group row w100" v-if="(product_price == 0)">
                    <label class="col-md-4 text-md-right mt-2">
                            Charge Paid By Customer
                    </label>

                    <div class="col-md-6">
                        <div class="form-control d-flex">
                            <label for="yes"><input v-model="is_charge_paid_customer" type="radio" id="yes" :true-value="1" value="1" name="is_charge_paid_customer">&nbsp;Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;
                            <label for="no"><input v-model="is_charge_paid_customer" type="radio" id="no" :true-value="0" value="0" name="is_charge_paid_customer">&nbsp;No</label>
                        </div>
                    </div>
                </div>

                <div class="form-group row w100">
                    <label class="col-md-4 text-md-right mt-2">
                            Exchange parcel
                    </label>

                    <div class="col-md-6">
                        <div class="form-control d-flex">
                            <label for="yes_exchange"><input v-model="is_exchange" type="radio" id="yes_exchange" :true-value="1" value="1" name="is_exchange">&nbsp;Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;
                            <label for="no_exchange"><input v-model="is_exchange" type="radio" id="no_exchange" :true-value="0" value="0" name="is_exchange">&nbsp;No</label>
                        </div>
                    </div>
                </div>

            </fieldset>

            <fieldset class="w100 pb-3">
                <legend>Pickup Info</legend>

                <div class="form-group row w100">
                    <label class="col-md-4 text-md-right mt-2">Merchent Name <span class="required">*</span></label>
                    <div class="col-md-6">
                        <input type="text" v-model="merchant_name" class="form-control" placeholder="Merchent Name" required>
                    </div>
                </div>

                <div class="form-group row w100">
                    <label class="col-md-4 text-md-right mt-2">Merchent Mobile <span class="required">*</span></label>
                    <div class="col-md-6">
                        <input type="text" v-model="merchant_mobile"  class="form-control" placeholder="Merchent Mobile" required>
                    </div>
                </div>

                <div class="form-group row w100 pb-md-2">
                    <label class="col-md-4 text-md-right mt-2">Pickup Address <span class="required">*</span></label>
                    <div class="col-md-6">
                        <textarea v-model="pickup_address" class="form-control" placeholder="Pickup Full Address" required></textarea>
                    </div>
                </div>

                <div class="form-group row w100">
                    <div class="col-md-4 mb-md-2">
                        <select v-model="pickup_division_id" class="form-control selectpicker" data-live-search="true" required>
                            <option value="">Division</option>
                            <option v-for="division in divisions" :value="division.id">{{division.name}}</option>
                        </select>
                    </div>

                    <div class="col-md-4 mb-md-2">
                        <select v-model="pickup_district_id" class="form-control selectpicker" data-live-search="true" required>
                            <option value="">District</option>
                            <option v-for="row in pickup_districts" :value="row.id">{{row.name}}</option>
                        </select>
                    </div>
                    
                    <div class="col-md-4 mb-md-2">
                        <select v-model="pickup_thana_id" class="form-control selectpicker" data-live-search="true" required>
                            <option value="">Thana</option>
                            <option v-for="row in pickup_thanas" :value="row.id">{{row.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="row w100">
                    <div class="col-md-12 text-right">
                        <div class="btn-group">
                            <button class="btn btn-success">Submit</button>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
        <div class="loader" v-if="is_submit">
            <div>
                <span><i class="fa fa-spinner fa-spin"></i></span>
                <span>{{loading}}...</span>
            </div>
        </div>

        <div class="message" v-if="notice">
            <div role="alert" aria-live="assertive" aria-atomic="true" data-autohide="false" class="toast fade show" style="background: rgb(255, 215, 218);">
                <div class="toast-header">
                    <strong class="mr-auto">Warning !!</strong> 
                    <button type="button" data-dismiss="toast" aria-label="Close" class="ml-2 mb-1 close" @click="noticeClose"><span aria-hidden="true">×</span></button>
                </div> 
                <div class="toast-body">{{notice}}</div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'NewParcel',
        props: {
            user:[Object]
        },
        data(){
            return {
                isOpenCart:false,
                is_submit : false,
                notice    : '',
                loading   : 'Loading',
                url       : '',
                divisions : [],
                kernel    : {},

                shipping_division_id : '',
                pickup_division_id   : '',

                shipping_districts   : [],
                shipping_district_id : '',

                pickup_districts   : [],
                pickup_district_id : '',

                shipping_thanas   : [],
                shipping_thana_id : '',

                pickup_thanas   : [],
                pickup_thana_id : '',

                merchant_name   : '',
                merchant_mobile : '',
                pickup_address  : '',

                customer_name    : '',
                customer_mobile  : '',
                shipping_address : '',

                categories : [],
                parcel_category_id : '',

                weights : [],
                package_weight_id: '',

                widths : [],
                package_width_id: '',

                heights : [],
                package_height_id: '',

                shipping_info : {},
                product_price : 0, 

                product_title : '',
                parcel_quantity : 1,

                is_charge_paid_customer : 1,

                is_exchange : 0,
            }
        },
        mounted(){
            this.url = this.$store.state.url;
            this.http('api/parcel-metarials', 'matarials');
            this.shipping_info = {
                parcel_type     : '...',
                parcel_from     : '...',
                parcel_to       : '...',
                return_charge   : 0,
                delivery_charge : 0,
                cod_percentage  : 0,
                cod_amount      : 0,
                total_price     : 0,
                product_price   : 0,
                notice          : 'Please Fill-Up All The Required Fields',
            };

            if(this.user){
                this.pickup_division_id = this.user.division_id;
                this.pickup_district_id = this.user.district_id;
                this.pickup_thana_id    = this.user.thana_id;
                this.pickup_address     = this.user.address;
                this.merchant_name      = this.user.name;
                this.merchant_mobile    = this.user.mobile;
            }
        },
        methods:{
            submit:function(){
                this.is_submit = true;
                var data = {
                    marchent_id : this.user.id,
                    customer_name           : this.customer_name,
                    customer_mobile         : this.customer_mobile,
                    shipping_full_address   : this.shipping_address,
                    shipping_division_id    : this.shipping_division_id,
                    shipping_district_id    : this.shipping_district_id,
                    shipping_thana_id       : this.shipping_thana_id,
                    company_name            : this.user.company_name,
                    marchent_name           : this.user.name,
                    marchent_mobile         : this.user.mobile,
                    pickup_full_address     : this.pickup_address,
                    pickup_division_id  : this.pickup_division_id,
                    pickup_district_id  : this.pickup_district_id,
                    pickup_thana_id     : this.pickup_thana_id,
                    parcel_type         : this.shipping_info.parcel_type,
                    product_price       : this.product_price,
                    delivery_charge     : this.shipping_info.delivery_charge,
                    cod_amount       : this.shipping_info.cod_amount,
                    cod_percentage   : this.shipping_info.cod_percentage,
                    return_charge    : this.shipping_info.return_charge,
                    cat_id      : this.parcel_category_id,
                    weight_id   : this.package_weight_id,
                    height_id   : this.package_height_id,
                    width_id    : this.package_width_id,
                    product_title     : this.product_title,
                    parcel_quantity   : this.parcel_quantity,
                    is_exchange       : this.is_exchange,
                    is_charge_paid_customer: this.is_charge_paid_customer,

                };
                this.http('api/parcel-request', 'parcelRequest', data);
            },
            noticeClose:function(){
                this.notice = '';
            },
            openCart:function(){
                if(this.isOpenCart) this.isOpenCart = false;
                else this.isOpenCart = true;
            },
            http:function(url, type, data=null){
                axios.post(this.$store.state.url+url, data)
                .then(response=>{this.kernel = Object.assign({}, {[type] : response.data});})
                .catch(err=>console.log(err));
            },
            refreshSelectpicker:function(){
                setTimeout(()=>{$('.selectpicker').selectpicker('refresh');}, 10);
            },
            fetchParcelCharge:function(){
                // CHECKING PARCEL TYPE
                if(this.shipping_district_id!='' && this.pickup_district_id!=''){
                    // CHECKING IF PARCEK IS SUB-CITY
                    if(this.shipping_division_id == this.pickup_division_id){
                        this.shipping_info.parcel_type = 'SUB CITY';

                        // CHECKING IF PARCEK IS IN-CITY
                        if(this.shipping_district_id == this.pickup_district_id){
                            this.shipping_info.parcel_type = 'IN CITY';
                        }
                    }
                    else {
                        this.shipping_info.parcel_type = 'OUT SIDE';
                    }

                    if(window.httpRequest) clearTimeout(window.httpRequest);
                    if(this.package_weight_id){
                        window.httpRequest = setTimeout(()=>{
                            this.http('api/get-parcel-charge', 'packageCharge', {
                                shipping_division_id : this.shipping_division_id,
                                shipping_district_id : this.shipping_district_id,
                                shipping_thana_id    : this.shipping_thana_id,

                                pickup_division_id    : this.pickup_division_id,
                                pickup_district_id    : this.pickup_district_id,
                                pickup_thana_id       : this.pickup_thana_id,
                                parcel_type           : this.shipping_info.parcel_type,

                                package_weight_id     : this.package_weight_id,
                                product_price         : this.product_price,
                                user_id               : this.user.id,
                            });
                        }, 500);   
                    }
                }
            }
        },
        computed:{
            shippingCost:function(){
                if(this.product_price > 0) this.is_charge_paid_customer = 1;
                // PICKUP THANA NAME
                if (this.pickup_thana_id) {
                    (this.pickup_thanas).map(thana=>{
                        if(this.pickup_thana_id==thana.id) this.shipping_info.parcel_from = thana.name;
                    })
                }

                // SHIPPING THANA NAME 
                if (this.shipping_thana_id) {
                    (this.shipping_thanas).map(thana=>{
                        if(this.shipping_thana_id==thana.id) this.shipping_info.parcel_to = thana.name;
                    })
                }

                this.shipping_info.product_price = this.product_price ? this.product_price : 0;
                this.shipping_info.total_price   = (+this.shipping_info.delivery_charge + +this.product_price);

                this.fetchParcelCharge();
                return (this.shipping_info.delivery_charge + this.shipping_info.cod_amount);
            }
        },
        watch:{
            kernel:function(){
                for(const key in this.kernel){
                    if(key=='packageCharge'){
                        this.shipping_info.delivery_charge  = this.kernel[key].charge;
                        this.shipping_info.cod_percentage   = this.kernel[key].cod_percentage;
                        this.shipping_info.cod_amount       = this.kernel[key].cod_amount;
                        this.shipping_info.notice           = this.kernel[key].notice;
                        this.shipping_info.return_charge    = this.kernel[key].return_charge;
                    }
                    else if(key=='shipping_districts'){
                        this.shipping_districts = this.kernel[key];
                        this.refreshSelectpicker();
                    }
                    else if(key=='pickup_districts'){
                        this.pickup_districts = this.kernel[key];
                        this.refreshSelectpicker();
                    }
                    else if(key=='shipping_thanas'){
                        this.shipping_thanas = this.kernel[key];
                        this.refreshSelectpicker();
                    }
                    else if(key=='pickup_thanas'){
                        this.pickup_thanas = this.kernel[key];
                        this.refreshSelectpicker();
                    }
                    else if(key=='matarials')
                    {
                        this.categories = this.kernel[key].categories;
                        this.divisions  = this.kernel[key].divisions;
                        this.weights    = this.kernel[key].weights;
                        this.heights    = this.kernel[key].heights;
                        this.widths     = this.kernel[key].widths;
                        this.refreshSelectpicker();
                    }
                    else if(key=='parcelRequest'){
                        var msg = this.kernel[key];
                        this.is_submit = false;
                        if(msg.is_submit==1){
                            this.loading = 'Redirecting';
                            window.location.href = msg.redirect;
                        }
                        else {
                            this.notice = msg.notice;
                        }
                    }
                }
            },
            shipping_division_id:function(){
                this.http('api/districts', 'shipping_districts', {where:{division_id:this.shipping_division_id}});
                this.shipping_districts = [];
                this.shipping_thanas    = [];
            },
            pickup_division_id:function(){
                this.http('api/districts', 'pickup_districts', {where:{division_id:this.pickup_division_id}});
                this.pickup_districts = [];
                this.pickup_thanas    = [];
            },
            shipping_district_id:function(){
                this.http('api/thanas', 'shipping_thanas', {where:{district_id:this.shipping_district_id}});
                this.shipping_thanas    = [];
            },
            pickup_district_id:function(){
                this.http('api/thanas', 'pickup_thanas', {where:{district_id:this.pickup_district_id}});
                this.pickup_thanas    = [];
            },
        }
    }
</script>

<template>
	<div class="col-lg-10 offset-lg-1">
		<div class="alert alert-danger" v-if="errors.length > 0">
			<ul class="m-0">
				<li v-for="error in errors">{{error}}</li>
			</ul>
		</div>
        <div class="row">
            <div class="col-md-1 offset-lg-2 pr-0">
                <div class="welcome_div"></div>
            </div>
            <div class="col-md-7 pl-md-0">
				<div class="form_div">
			        <h2>
			        	<span v-if="stap==1">Change Your Password</span>
			        	<span v-if="stap==2">OTP Validation</span>
			        	<span v-if="stap==3">New Password</span>
			        </h2>
			        <form @submit.prevent="getOTP()" method="POST">

			            <div class="form-group" v-if="stap==1">
			                <input type="email" v-model="form.email" placeholder="Enter Your E-mail" class="form-control" autocomplete="off" required>
			                <label>Enter Your E-mail</label>
			            </div>

			            <div class="form-group" v-if="stap==2">
			                <input type="text" v-model="form.otp" placeholder="Enter OTP CODE" class="form-control" autocomplete="off" required>
			                <label>OTP</label>
			            </div>

			            <div v-if="stap==3">
				            <div class="form-group">
				                <input type="password" v-model="form.password" placeholder="Enter New Password" class="form-control" autocomplete="off" required>
				                <label>Enter New Password</label>
				            </div>

				            <div class="form-group">
				                <input type="password" v-model="form.password_confirmation" placeholder="Enter Confirm Password" class="form-control" autocomplete="off" required>
				                <label>Enter Confirm Password</label>
				            </div>
			            </div>
			            <!-- // -->
			            <div class="btn-group">
			                <button type="submit" class="btn sign-up">
				                <span v-if="is_submit"><i class="fa fa-spinner fa-pulse"></i></span>
				                <span v-else >
				                	<span v-if="stap==3">Submit</span>
				                	<span else >Next</span>
				                </span>
				            </button>
			            </div>

			        </form>
			    </div>
            </div>
        </div>
    </div>
</template>
<script>
	export default {
		name:'Forgot-Password',
		props:['forgot_path', 'base_url'],
		data(){
			return {
				form:{},
				errors:[],
				is_submit:false,
				stap:1
			}
		},
		methods:{
			getOTP(){
				this.errors = [];
				this.is_submit = true;
				this.form.type = (this.stap==1 ? 'get-opt' : (this.stap==2 ? 'otp-validation' : (this.stap==3 ? 'password-change':'')));

				axios.post(this.forgot_path, this.form)
				.then(response=>{
					this.is_submit = false;
					if(response.data.errors){
						this.errors = Object.values(response.data.errors);
					}
					else {
						if(this.stap == 1)
							this.stap = 2;
						else if(this.stap == 2)
							this.stap = 3;
						else if(this.stap == 3){
							window.location.href = this.base_url+'user/password-change-success'
						}
					}
				})
				.catch(error=>console.log(error));
			}
		}
	}
</script>
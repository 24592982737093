<template>
    <section class="tracking_section" id="tracking">
        <div class="container" v-if="this.page!='track-page'">
            <div class="tracking_title">
                <h6>Tracking Order</h6>
                <h2>Your Parcel Status</h2>
            </div>
            <div class="tracking_form">
                <form action="#" method="POST">
                    <input type="text" v-model="parcelCode" placeholder="Type Your Track ID" class="form-control">
                    <button type="button" class="btn" @click="trackParcel">Track Now</button>
                </form>
            </div>
            <div class="owl-carousel tracking_status">
                <div class="status_box">
                    <h3 class="status">500</h3>
                    <h5>Parcels Delivered</h5>
                </div>
                <div class="status_box">
                    <h3 class="status">210</h3>
                    <h5>Districts Coverage</h5>
                </div>
                <div class="status_box">
                    <h3 class="status">110</h3>
                    <h5>Delivery Agents</h5>
                </div>
                <div class="status_box">
                    <h3 class="status">310</h3>
                    <h5>Registered Merchants</h5>
                </div>
            </div>
        </div>

        <div class="parcel-track-wrapper" v-if="is_show">
            <div class="parcel-track">
                <button class="close-btn" @click="close" v-if="this.page!='track-page'">&times;</button>
                <div class="parcel-status">
                    <div v-if="loader!='empty'">
                        <div class="tracking-wrapper">
                            <div class="search-parcel search-field-one">
                                <input type="search" >
                                <button>
                                    <i class="fa fa-search" aria-hidden="true"></i>
                                </button>
                            </div>

                            <div class="track-id">
                                <div>
                                    <p><strong>Track ID </strong>: #{{parcel.parcel_invoice}}</p>
                                </div>
                                <div class="search-parcel search-field-two">
                                    <input type="search" v-model="parcelCode" >
                                    <button @click="trackParcel">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>

                            <div class="receiver">
                                <div class="icon">
                                    <i class="fa fa-id-card-o" aria-hidden="true"></i>
                                </div>
                                <div class="receiver-info">
                                    <h4><strong>Customer</strong>: {{parcel.customer_name}}</h4>
                                    <h4><strong>C.Mobile</strong>: {{parcel.customer_mobile}}</h4>
                                    <p>{{parcel.shipping_full_address}}</p>
                                </div>
                            </div>
                            <div class="parcel-pd">
                                <div class="track-wp-min">
                                    <ul class="tracking">
                                        <li v-for="(row, index) in status" :key="index">
                                            <span class="date">{{row.date}}</span>
                                            <span class="icon"><i class="fa fa-motorcycle" aria-hidden="true"></i></span>
                                            <h4 style="text-transform: capitalize;">{{(row.status ? row.status : '').replace('_', ' ')}}</h4>
                                            <p>{{row.remark}}</p>
                                        </li>
                                    </ul>
                                </div>
                                <div class="parcel-info">
                                    <ul>
                                        <li>
                                            <strong>Shop Details</strong>
                                            <table>
                                                <tr>
                                                    <th width="60px">Shop</th>
                                                    <td>: {{parcel.company_name}}</td>
                                                </tr>
                                                <tr>
                                                    <th>Merchant</th>
                                                    <td>: {{parcel.marchent_name}}</td>
                                                </tr>
                                                <tr>
                                                    <th>M.Mobile</th>
                                                    <td>: {{parcel.marchent_mobile}}</td>
                                                </tr>
                                                <tr>
                                                    <th>Address</th>
                                                    <td>: {{parcel.pickup_full_address}}</td>
                                                </tr>
                                            </table>
                                        </li>
                                        <li>
                                            <strong>Parcel Details</strong>
                                            <table>
                                                <tr>
                                                    <th width="60px">Date</th>
                                                    <td>: {{parcel.date}}</td>
                                                </tr>
                                                <tr>
                                                    <th>Product</th>
                                                    <td>: {{parcel.product_title}}</td>
                                                </tr>
                                                <tr>
                                                    <th>Weight</th>
                                                    <td>: {{parcel.weight}}</td>
                                                </tr>
                                                <tr>
                                                    <th>Quantity</th>
                                                    <td>: {{parcel.parcel_quantity}}</td>
                                                </tr>
                                                <tr>
                                                    <th>Type</th>
                                                    <td style="text-transform: capitalize;">: {{(parcel.parcel_type).replace('_', ' ')}}</td>
                                                </tr>
                                                <tr>
                                                    <th>Price</th>
                                                    <td>: {{parcel.product_price}}</td>
                                                </tr>
                                                <tr>
                                                    <th>Charge</th>
                                                    <td>: {{parcel.delivery_charge}}</td>
                                                </tr>
                                                <tr>
                                                    <th>COD</th>
                                                    <td>: {{parcel.cod_amount}}</td>
                                                </tr>
                                            </table>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="empty" v-if="loader=='empty'">
                        <span>Your Parcel is not Found</span>
                    </div>
                </div>

                <div class="loader" v-if="loader=='loading'">
                    <div style="display: grid; text-align: center;">
                        <span><i class="fa fa-refresh fa-spin fa-3x fa-fw"></i></span>
                        <span>Tracking Your Parcel</span>
                    </div>
                </div>

            </div>
        </div>

    </section>
</template>

<script>
    export default {
        name: 'ParcelTrack',
        props:['status_link', 'page', 'tracking_id'],
        data(){
            return {
                is_show     : false,
                parcelCode  : '',
                url         : '',
                parcel      : {},
                status      : [],
                loader      : "",
            }
        },
        mounted(){
            this.url = this.$store.state.url;
            this.parcelCode = this.tracking_id;
            if(this.tracking_id){
                this.trackParcel();
            }
            if(this.page=='track-page')
                this.is_show = true;
        },
        methods:{
            trackParcel:function(){
                this.loader = "loading";
                this.status = [];
                this.is_show = true;
                if(this.page!='track-page')
                document.body.style.overflow = 'hidden';
                // 
                if(this.parcelCode!=""){
                    axios.post(this.url+"api/get-parcel-status", {
                        invoice_no:this.parcelCode
                    })
                    .then(response=>{
                        var data = response.data;
                        if(data.parcel){
                            this.status = data.status;
                            this.parcel = data.parcel;
                            this.loader = "";
                            this.statusBeautify();
                        }
                        else {
                            this.loader = "empty";
                        }
                    })
                    .catch(err=>console.log(err));
                }
                else {
                    this.loader = "empty";
                }
            },
            close:function(){
                this.is_show = false;
                document.body.style.overflow = 'auto';
            },
            statusBeautify:function(){
                setTimeout(()=>{
                    var status_list = readAll('#track_status li');
                    if(status_list){
                        Object.values(status_list).forEach((item, key)=>{
                            if((key+1)%2==0){
                                item.style.marginLeft = `-${item.offsetWidth-2}px`;
                                item.style.textAlign = `right`;
                            }
                        });
                    } 
                }, 10);
                 
            }
        }
    }
</script>
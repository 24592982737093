<template>
    <div class="company_info">
        <h3>{{title}}</h3>
        <form class="contact-form" @submit.prevent="submit">
            <div class="form-group">
                <input type="text" v-model="name" class="form-control" placeholder="Full Name" required autocomplete="off">
            </div>
            <div class="form-group">
                <input type="text" class="form-control" v-model="phone" placeholder="Mobile" required autocomplete="off">
            </div>
            <div class="form-group">
                <textarea class="form-control" v-model="message" rows="4" placeholder="Message" required></textarea>
            </div>
            <button type="button" v-if="sending" class="btn"><i class="fa fa-spinner fa-pulse fa-fw"></i> Sending...</button>
            <button type="submit" v-else class="btn">Submit</button>
        </form>
    </div>
</template>

<script>
    export default {
        props:['title'],
        name: 'InstanceContact',
        data(){
            return {
                sending : false,
                name    : '',
                phone   : '',
                message : '',
            }
        },
        methods:{
            submit:function (){
                this.sending = true;
                axios.post(url+'api/fontent/contact', {
                    name    : this.name,
                    phone   : this.phone,
                    message : this.message
                })
                .then(response=>{
                    this.sending = false;
                    if(response.data==1){
                        this.name    = '';
                        this.phone   = '';
                        this.message = '';
                        new Toast({
                          message: `
                            <h4>Your Message Successfully Sent</h4>
                            <p>Please Wait For Our Response</p>
                          `,
                          type: 'success',
                        });
                    }
                })
                .catch(err=>console.log(err));
            }
        }
    }
</script>
